<template>
  <div>
    <KTPortlet :title="referencedForm === null ? 'Form Results' : `${ referencedForm.name } Results`">
      <!-- <template v-slot:toolbar v-if="start !== null && end !== null">
          {{ fstart }} - {{ fend }}
        </template> -->
      <template v-slot:toolbar>
        <!-- <v-btn color="info" @click="editNewForm(null)" style="text-align: center;" class="ma-1">NEW FORM
        </v-btn> -->
      </template>
      <template slot="body">
        <!-- <b-button @click="openBuilder" style="text-align: center;" variant="info text-light" class="ma-1">OPEN
        </b-button> -->
        <Loader v-if="formResponses === null" :newStyle="2" :fields="[
          'Status',
          'Number',
          'Total',
          'Amount Due',
          'Created Date',
          'Due Date',
          ''
        ]"></Loader>
        <div v-else-if="formResponses.length === 0" style="width: 100%; text-align: center">
          No form responses to display
        </div>
        <div v-else>
          <b-table-simple responsive>
            <b-thead>
              <b-tr>
                <b-th></b-th>
                <b-th>Cluster:</b-th>
                <!-- <b-th>Pending Changes</b-th> -->
                <b-th v-for="field of fieldClusters" v-bind:key="field.label" :colspan="field.span">{{ field.label }}
                </b-th>
              </b-tr>
              <b-tr>
                <b-th>Date</b-th>
                <b-th>IP</b-th>
                <!-- <b-th>Pending Changes</b-th> -->
                <b-th v-for="field of fields" v-bind:key="field.key">{{ field.label }}</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr v-for="response in formResponses" v-bind:key="response.id">
                <b-td>{{ new Date(response.date).toISOString().split('T').join(' ').split('.')[0] }}</b-td>
                <b-td>{{ response.ip }}</b-td>
                <b-td v-for="field of fields" v-bind:key="field.key">{{ getResponseValue(response, field) }}</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </div>
      </template>
    </KTPortlet>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import TLib from "./lib";
import Loader from "@/views/partials/content/Loader.vue";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import { saveAs } from "file-saver";

export default {
  data() {
    return {
      formResponses: null,
      referencedForm: null,
      fields: []
    };
  },
  components: {
    KTPortlet,
    Loader
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    icon() {
      return this.layoutConfig().self.logo.favicon || '/favicon-primary.png';
    },
    fieldClusters() {
      let clusters = [];
      for (let field of this.fields) {
        if (field.cluster === null) {
          clusters.push({
            label: '',
            span: 1
          });
          continue;
        }
        let foundIndex = -1;
        for (let i = 0; i < clusters.length; i++) {
          if (clusters[i].label === field.cluster) {
            foundIndex = i;
            break;
          }
        }

        if (foundIndex === -1) {
          clusters.push({
            label: field.cluster,
            span: 1
          });
          continue;
        }
        clusters[foundIndex].span++;
      }
      return clusters;
    }
  },
  methods: {
    b64toBlob(b64Data, contentType = '', sliceSize = 512) {
      const byteCharacters = atob(b64Data);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: contentType });
      return blob;
    },
    downloadImage(response) {
      saveAs(this.b64toBlob(response.reference), response.value);
    },
    getResponseValue(response, field) {
      for (let respData of response.data) {
        if (respData.name === field.name) {
          switch (respData.type) {
            case "tripetto-block-date": {
              if (!this.$tools.isNullOrUndefined(respData.value)) {
                return new Date(respData.value).toISOString().split('T').join(' ').split('.')[0];
              }
              break;
            }
          }
          return respData.value || '-';
        }
      }
      return '';
    },
    updateForm(id) {
      const self = this;
      TLib.getForms(self, self.$route.params.serviceId).then(x => {
        for (let form of x) {
          if (form.id === id) {
            self.$data.referencedForm = form;
            self.$store.dispatch(SET_BREADCRUMB, [
              { title: "Communication" },
              { title: "Forms" },
              { title: form.name },
              { title: "Results" },
            ]);
            self.updateFormFields(form);
            return;
          }
        }
        self.$router.push(`/Communication/${ self.$route.params.serviceId }/Forms`);
      });
    },
    updateFormFields(form) {
      const self = this;
      const ignoredNodeTypes = ["tripetto-block-setter"];
      this.$data.fields = [];
      let fieldsList = [];
      if (this.$tools.isNullOrUndefined(form.config)) return;
      if (this.$tools.isNullOrUndefined(form.config.config)) return;
      if (this.$tools.isNullOrUndefined(form.config.config.clusters)) return;
      for (let cluster of form.config.config.clusters) {
        if (!this.$tools.isNullOrUndefined(cluster.branches)) {
          const cbClusterName = (!self.$tools.isNullOrUndefined(cluster.name) ? `${ cluster.name } - ` : 'Cluster Condition - ');
          for (let branch of cluster.branches) {
            if (this.$tools.isNullOrUndefined(branch.clusters)) continue;
            for (let bcluster of branch.clusters) {
              if (!this.$tools.isNullOrUndefined(bcluster.nodes)) {
                let filteredNodes = bcluster.nodes.filter(x => ignoredNodeTypes.indexOf(x.block.type) < 0);
                for (let node of filteredNodes) {
                  fieldsList.push({
                    cluster: `${ cbClusterName }${ bcluster.name || '?' }`,
                    clusterSpan: filteredNodes.length,
                    key: node.id,
                    name: node.name,
                    label: node.label || node.name,
                    type: node.block.type
                  });
                }
              }
            }
          }
        }
        if (!this.$tools.isNullOrUndefined(cluster.nodes)) {
          let filteredNodes = cluster.nodes.filter(x => ignoredNodeTypes.indexOf(x.block.type) < 0);
          for (let node of filteredNodes) {
            fieldsList.push({
              cluster: cluster.name || null,
              clusterSpan: filteredNodes.length,
              key: node.id,
              name: node.name,
              label: node.label || node.name,
              type: node.block.type
            });
          }
        }
      }
      this.$data.fields = fieldsList;
    },
    updateResults(id) {
      const self = this;
      TLib.getFormResponses(self, self.$route.params.serviceId, id).then(x => {
        self.$data.formResponses = x;
      });
    }
  },
  beforeDestroy() {
    this.$eventBus.off(`switch-service-selected`);
    //this.$eventBus.off(`ws-communication-whatsapp`);
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Communication" },
      { title: "Forms" },
      { title: "Results" },
    ]);

    let self = this;

    self.$eventBus.on(`switch-service-selected`, x => {
      self.$router.push(`/Communication/${ x }/Forms`);
    });

    self.updateForm(this.$route.params.formId);
    self.updateResults(this.$route.params.formId);
  }
};
</script>