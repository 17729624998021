var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('KTPortlet',{attrs:{"title":_vm.referencedForm === null ? 'Form Results' : `${ _vm.referencedForm.name } Results`},scopedSlots:_vm._u([{key:"toolbar",fn:function(){return undefined},proxy:true}])},[_c('template',{slot:"body"},[(_vm.formResponses === null)?_c('Loader',{attrs:{"newStyle":2,"fields":[
        'Status',
        'Number',
        'Total',
        'Amount Due',
        'Created Date',
        'Due Date',
        ''
      ]}}):(_vm.formResponses.length === 0)?_c('div',{staticStyle:{"width":"100%","text-align":"center"}},[_vm._v(" No form responses to display ")]):_c('div',[_c('b-table-simple',{attrs:{"responsive":""}},[_c('b-thead',[_c('b-tr',[_c('b-th'),_c('b-th',[_vm._v("Cluster:")]),_vm._l((_vm.fieldClusters),function(field){return _c('b-th',{key:field.label,attrs:{"colspan":field.span}},[_vm._v(_vm._s(field.label)+" ")])})],2),_c('b-tr',[_c('b-th',[_vm._v("Date")]),_c('b-th',[_vm._v("IP")]),_vm._l((_vm.fields),function(field){return _c('b-th',{key:field.key},[_vm._v(_vm._s(field.label))])})],2)],1),_c('b-tbody',_vm._l((_vm.formResponses),function(response){return _c('b-tr',{key:response.id},[_c('b-td',[_vm._v(_vm._s(new Date(response.date).toISOString().split('T').join(' ').split('.')[0]))]),_c('b-td',[_vm._v(_vm._s(response.ip))]),_vm._l((_vm.fields),function(field){return _c('b-td',{key:field.key},[_vm._v(_vm._s(_vm.getResponseValue(response, field)))])})],2)}),1)],1)],1)],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }